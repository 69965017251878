body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
a {
	text-decoration: none !important;
	color: white !important;
}
/* Login Page */
.loginHeading {
	margin-top: 1rem;
	margin-bottom: 2rem;
	text-align: center;
	font-size: 4.5rem;
	font-weight: 800;
	letter-spacing: 0.5rem;
	background-image: linear-gradient(90deg, #cdf1a3, #ff0048);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}

/* Main */
.main {
	display: flex;
}

/* Navbar */
.qf-navbar {
	width: 18rem;
	min-width: 18rem;
	height: 100vh;
	background-color: rgb(30, 30, 30);
	padding: 1rem;
}
.qf-navbar h1 {
	padding: 0.5rem 0 0.5rem 0;
	color: white;
	font-weight: bold;
	width: 100%;
	text-align: center;
	font-weight: 700;
	cursor: pointer;
	background-image: linear-gradient(90deg, #eaffd1, #ff0048);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	border-bottom: 0.2rem solid #eaffd1;
}
.nav-items {
	margin-top: 2rem;
	height: 45vh;
}
.nav-items li {
	list-style: none;
	height: 3.5rem;
}
.nav-items .nav-item {
	text-align: left;
	text-decoration: none;
	color: white;
	font-weight: bolder;
	margin-left: 0.5rem;
	font-size: 1.4rem;
	transition-duration: 1s;
}
.nav-items .nav-item:hover {
	color: aqua;
}
.toggle {
	display: none;
}
.active {
	color: rgb(0, 255, 0) !important;
}
.logout-section a {
	color: white !important;
}
/* Common Section */
.sections {
	width: 100%;
	padding: 0.6rem;
}
.section-heading {
	padding: 0.6rem 0 0.4rem 0;
	color: white;
	font-weight: bold;
	width: 100%;
	text-align: center;
	font-weight: 700;
	font-size: 2.8rem;
	background-image: linear-gradient(90deg, #000000, #8400ff);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}

/* Media Query */
@media (max-width: 767px) {
	.toggle {
		display: block;
		position: absolute;
		left: 1rem;
		top: 1rem;
		height: 2rem;
		width: 2rem;
		z-index: 1000;
		background-color: rgb(0, 255, 0);
		border: none;
		border-radius: 50%;
		color: white;
	}
	.toggle .line {
		margin: 0.2rem 0.15rem;
		height: 0 !important;
		border: 1px solid white !important;
		background-color: white !important;
	}
	.shownav {
		left: 0 !important;
		position: absolute;
	}
	.hidenav {
		display: none;
	}
}

/* Power BI */
.iframe {
	width: 100%;
	height: 82vh;
}

/* Error Page */
.class-404 {
	font-size: 10rem;
	font-weight: 900;
	letter-spacing: 10px;
	background-image: linear-gradient(90deg, #cdf1a3, #ff0048);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	margin-top: 2rem;
}

.class-oops {
	font-size: 4rem;
	background-image: linear-gradient(90deg, #152700, #1100ff);
}

.getbackbtn {
	color: white;
	font-size: 2rem;
	font-weight: 600;
	padding: 10px 20px;
	border-radius: 10px;
	outline: none;
	border: none;
	background: linear-gradient(45deg, #a7ff43, #0aefff) !important;
	transition: all 1s;
}

.getbackbtn:focus {
	outline: none;
}

.getbackbtn:hover {
	transform: translateX(2px) translateY(-4px);
}

@media (max-width: 1200px) {
	.class-404 {
		font-size: 8rem;
	}
	.res-oops {
		display: block;
	}
	.class-oops {
		font-size: 3.5rem;
	}
}

@media (max-width: 768px) {
	.class-404 {
		font-size: 6rem;
	}
	.class-oops {
		font-size: 3rem;
	}
}

@media (max-width: 768px) {
	.getbackbtn {
		font-size: 1.5rem;
	}
}

/* Contact */
.contact-section {
	background-color: #f7fdfd;
}
.formSection {
	background-color: var(--color-profile-dark);
}
.heading {
	font-size: 3rem;
	text-align: center;
	font-weight: bold;
}
.inputField {
	border-radius: 0.5rem;
	padding: 0.625rem !important;
	font-size: 1.3rem !important;
	outline: none;
}
.inputField:focus {
	outline: none;
}
.labelStyle {
	font-size: 1.2rem !important;
	padding-bottom: 0.25rem;
	font-weight: bold;
	line-height: 2rem;
	margin: 0.2rem 0;
}
.formError {
	color: rgb(192, 33, 33);
	margin-top: 0.5rem;
	font-size: 1.1rem;
}
.textarea {
	resize: none !important;
	padding: 0.625rem !important;
	font-size: 1.2rem !important;
	border-radius: 0.5rem;
}
.map {
	padding-left: 2rem !important;
}
.formButton {
	padding: 1.2rem 2rem;
	background-color: black;
	color: white;
	font-weight: bold;
	font-size: 1.3rem;
	text-transform: uppercase;
	width: 16rem;
	outline: none;
	border: none;
	cursor: pointer;
	border-radius: 0.625rem;
	transition-duration: 1s;
}
.formButton:hover {
	background-color: blue;
}
.loader-div {
	width: 16rem;
	display: flex;
	justify-content: center;
}
@media (max-width: 768px) {
	.map {
		padding-left: 1rem !important;
	}
}
